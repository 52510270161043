import { FC, MouseEvent } from 'react';

interface FavoriteIconProps {
  className?: string;
  fillColor?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => Promise<void>;
}

export const FavoriteIcon: React.FC<FavoriteIconProps> = ({ className, onClick, fillColor = 'white' }) => {
  return (
    <button onClick={onClick}>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <rect width="40" height="40" rx="20" fill="black" fillOpacity="0.75" />
      <path
        d="M21.2276 12.32L21.2278 12.3198C23.277 10.1719 26.5563 9.85637 28.7385 11.7453C31.2789 13.948 31.4153 17.9129 29.1349 20.3035L29.1348 20.3036L20.6317 29.223C20.6316 29.223 20.6316 29.223 20.6316 29.2231C20.2793 29.5923 19.717 29.5923 19.3647 29.2231C19.3646 29.223 19.3646 29.223 19.3646 29.223L10.8616 20.3038C8.58533 17.9132 8.72166 13.9481 11.262 11.7454C13.4444 9.85619 16.728 10.1722 18.7725 12.3194L18.7731 12.32L19.6388 13.2263L20.0003 13.6047L20.3619 13.2263L21.2276 12.32Z"
        stroke={fillColor}
      />
    </svg>
    </button>
  );
};
