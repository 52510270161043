import { SortType } from 'src/shared/types';

export const emptyError = 'Поле не может быть пустым';

export enum routesEnum {
  LOGIN = '/login',
  REGISTER = '/register',
  HOME = '/',
  CATALOG = '/catalog',
  CATALOG_SEARCH = '/catalog/search',
  BASKET = '/basket',
  PRODUCT = '/product/:id',
  PAYMENT = '/payment/:id',
  END_PAYMENT = '/end_payment',
  FAVORITES = '/favorites',
  PROFILE_ORDERS = '/profile/orders',
  PROFILE_ORDERS_MOBILE = '/profile/orders/mobile',
  PROFILE_ABOUT = '/profile/about',
  POLICY = '/policy',
  PROFILE_CUSTOMIZATION = '/profile/customization',
  CONTACT_SUPPORT = '/profile/support',
  CHAT_SUPPORT = '/profile/support/:id',
  SBERID_CALLBACK = '/sberid-callback',
  DELIVERY_DETAILS = '/delivery_details',
  PERSONAL_INFORMATION = '/profile/personal_information',
  AVAILABLE_EMAILS = '/profile/available_emails',
  PROFILE_SETTINGS = '/profile/settings'
}

export const sort: SortType[] = [
  { value: 'name|asc', label: 'По имени' },
  { value: 'price|asc', label: 'Сначала дешевле' },
  { value: 'price|desc', label: 'Сначала дороже' },
  { value: 'rating|desc', label: 'Высокий рейтинг' },
];

export const initialInterval = { min: 0, max: 1000 };

export const offsetStep = 12;
