export const selectProducts = (state) => state.productFilterSlider.products;
export const selectFilteredProducts = (state) => state.productFilterSlider.filteredProducts;
export const selectCurrentFilter = (state) => state.productFilterSlider.currentFilter;
export const selectCurrentPage = (state) => state.productFilterSlider.currentPage;
export const selectItemsPerPage = (state) => state.productFilterSlider.itemsPerPage;
export const selectIsLoading = (state) => state.productFilterSlider.isLoading;
export const selectError = (state) => state.productFilterSlider.error;

export const selectVisibleProducts = (state) => {
  const { filteredProducts, currentPage, itemsPerPage } = state.productFilterSlider;
  const start = currentPage * itemsPerPage;
  const end = start + itemsPerPage;
  return filteredProducts.slice(start, end);
};

export const selectTotalPages = (state) => {
  const { filteredProducts, itemsPerPage } = state.productFilterSlider;
  return Math.ceil(filteredProducts.length / itemsPerPage);
};