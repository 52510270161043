import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useSlider } from '../lib/useSlider';
import { useProducts } from "src/pages/HomePage/hooks/useProducts";
import { ProductType } from "src/shared/api/catalog";
import { useAppDispatch } from "src/shared/store/store";
import { fetchProducts } from "src/feature/FilterSlider/model/action";
import {
  selectCurrentFilter,
  selectCurrentPage,
  selectError,
  selectTotalPages
} from "src/feature/FilterSlider/model/getters";
import { nextPage, prevPage, setFilter } from "src/feature/FilterSlider/model";
import { FilterTabs } from "src/feature/FilterSlider/ui/FilterTabs/FilterTabs";
import { SliderControls } from "src/feature/FilterSlider/ui/SliderControls/SliderControls";
import { ProductGridCard } from "src/feature/ProductGridCard";
import mainPageBlueBg from "src/shared/assets/images/mock/mainPage_blueBg.gif";
import mainPageBlueBgMobile from "src/shared/assets/images/mock/mainPage_blueBg-mobile.gif";

export const ProductSlider = () => {
  const dispatch = useAppDispatch();
  const currentFilter = useSelector(selectCurrentFilter);
  const currentPage = useSelector(selectCurrentPage);
  const totalPages = useSelector(selectTotalPages);
  const error = useSelector(selectError);

  const { cardWidth, gap, containerWidth } = useSlider();
  const sliderRef = useRef<HTMLDivElement>(null);
  const { isLoading, products, setProducts } = useProducts();

  const onFavorite = (product: ProductType) => {
    setProducts(prev =>
      prev.map(el => (el.id === product.id ? { ...el, favorite: !el.favorite } : el)),
    );
  };

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    if (sliderRef.current && containerWidth > 0) {
      const offset = currentPage * (containerWidth - gap);
      sliderRef.current.style.transform = `translateX(-${offset}px)`;
    }
  }, [currentPage, containerWidth, gap]);

  const handleFilterChange = (filter: string) => {
    dispatch(setFilter(filter as any));
  };

  const handleNext = () => {
    if (currentPage < totalPages - 1) {
      dispatch(nextPage());
    }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      dispatch(prevPage());
    }
  };

  if (isLoading) return <div>Загрузка...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
      <style>
        {
          `
          .bg-slider {
          background-image: url(${mainPageBlueBg});
            background-size: cover;
          }
          
          @media (min-width: 768) {
            .bg-slider {
              background-image: url(${mainPageBlueBgMobile});
            }
          }
        `}
      </style>

      <div className="bg-slider mx-auto max-w-[1720px] md:rounded-[20px] px-4 pt-[20px] pb-[22px] slider-container md:pt-[68px] md:pb-[95px] mb-[40px] md:mb-[120px]">
        <div className="flex w-full justify-between mb-6 md:mb-8 md:px-[80px]">
          <FilterTabs
            currentFilter={currentFilter}
            onFilterChange={handleFilterChange}
          />

          {totalPages > 1 && (
            <SliderControls
              onNext={handleNext}
              onPrev={handlePrev}
              currentPage={currentPage}
              totalPages={totalPages}
            />
          )}
        </div>

        <div className="relative overflow-hidden md:px-[80px]">
          <div
            ref={sliderRef}
            className="flex transition-transform duration-300"
            style={{
              gap: `${gap}px`,
              width: 'fit-content',
            }}
          >
            {products.map(product => (
              <div
                key={product.id}
                style={{
                  width: `${cardWidth}px`,
                  flexShrink: 0,
                }}
              >
                <ProductGridCard
                  onFavorite={onFavorite}
                  key={product.id}
                  data={product}
                  cardStyles={{ height: '100%', width: '100%' }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};