import React, {FC, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentIndex, selectSliderCards } from "src/feature/SpecialOffer/model/getters";
import { Link } from "react-router-dom";
import { useSliderControls } from "src/feature/SpecialOffer/lib/useSliderControls";
import { useAppDispatch } from "src/shared/store/store";
import { fetchSliderSpecialOfferList } from "src/feature/SpecialOffer/model/action";
import { ReactComponent as ArrowSmallIcon } from 'src/shared/assets/icons/kit/arrow-small.svg';
import cn from "classnames";

export const SpecialOffer: FC = () => {
  const cards = useSelector(selectSliderCards);
  const currentIndex = useSelector(selectCurrentIndex);
  const { handleNext, handlePrev } = useSliderControls();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchSliderSpecialOfferList());
  }, [dispatch]);


  // const visibleCards: any = [];

  // for (let i = 0; i < 4; i++) {
  //   const cardIndex = (currentIndex + i) % cards.length;
  //   visibleCards.push(cards[cardIndex]);
  // }

  const visibleCards = Array.from({ length: 4 }, (_, i) =>
    cards[(currentIndex + i) % cards.length]
  );

  if (cards.length === 0) return null;

  return (
    <div className="hidden md:block relative my-8 mb-[120px]">
      <div className="container max-w-[1720px] mx-auto px-4">
        <div className="flex justify-between">
          <h2 className="text-2xl font-bold mb-6">Играй бесплатно</h2>

          <div>
            <div className="hidden md:flex justify-between items-center gap-3">
              <button
                onClick={handlePrev}
                className={cn(
                  'bg-bgPrimary text-primary-200',
                  'aspect-square h-7 rounded-[50%]'
                )}
                aria-label="Предыдущая страница"
              >
                <ArrowSmallIcon className="rotate-0 h-3 stroke-[1.5]"/>
              </button>

              <button
                onClick={handleNext}
                className={cn('bg-bgPrimary text-primary-200',
                  'aspect-square h-7 rounded-[50%]',
                )}
                aria-label="Следующая страница"
              >
                <ArrowSmallIcon className="rotate-180 h-3 stroke-[1.5]"/>
              </button>
            </div>
          </div>
        </div>

        <div className="relative">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {visibleCards.map((card: any) => (
              <Link
                to={card.link}
                className="flex flex-col rounded-lg"
              >
                <img
                  src={card.image}
                  alt={card.title}
                  className="w-full h-[437px] max-w-[407px] object-cover rounded-[20px] mb-5"
                />
                <h3 className="text-[24px] leading-[24px] font-[500]">{card.title}</h3>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};