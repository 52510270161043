import React from 'react';
import cn from "classnames";

import { ReactComponent as ArrowSmallIcon } from 'src/shared/assets/icons/kit/arrow-small.svg';

interface SliderControlsProps {
  onNext: () => void;
  onPrev: () => void;
  currentPage: number;
  totalPages: number;
}

export const SliderControls: React.FC<SliderControlsProps> = ({
  onNext,
  onPrev,
  currentPage,
  totalPages
  }) => {

  return (
    <div className="hidden md:flex justify-between items-center gap-3">
      <button
        onClick={onPrev}
        className={cn(
          currentPage > 0
            ? 'bg-primary-200 text-bgPrimary'
            : 'bg-bgPrimary text-primary-200',
          'aspect-square h-7 rounded-[50%]'
        )}
        aria-label="Предыдущая страница"
      >
        <ArrowSmallIcon className="rotate-0 h-3 stroke-[1.5]"/>
      </button>

      <button
        onClick={onNext}
        className={cn(
          currentPage < totalPages - 1
            ? 'bg-primary-200 text-bgPrimary'
            : 'bg-bgPrimary text-primary-200',
          'aspect-square h-7 rounded-[50%]',
        )}
        aria-label="Следующая страница"
      >
        <ArrowSmallIcon className="rotate-180 h-3 stroke-[1.5]"/>
      </button>
    </div>
  );
};