import { ApiPayment } from 'src/shared/api/ApiPayment';
import { GetConfirmationTokenParams } from 'src/shared/api/payment/types';
import TokenService from 'src/shared/api/token/TokenService';

const getConfirmationToken = (params: GetConfirmationTokenParams) => {
  const sessionId = TokenService.getSessionToken() || '';

  return ApiPayment.post(`/api/Payment/create-payment`, { ...params, sessionId });
};

const getOrders = (userId: number) => {
  return ApiPayment.get(`/api/Payment/order-history?userId=${userId}`);
};

const setOrderVisibility = (orderId: number, visibility: boolean) => {
  return ApiPayment.get(`/api/Payment/order-visibility?orderId=${orderId}&hidden=${visibility}`);
};

const PaymentService = { getConfirmationToken, getOrders, setOrderVisibility };

export default PaymentService;
