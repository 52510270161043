import { FC } from 'react';
import { Container } from 'src/entity/Container';
import { PromoSlider } from "src/feature/PromoSlider";
import { AdvertisementBlock } from "src/feature/AdvertisementBlock";
import { ProductSlider } from "src/feature/FilterSlider";
import { ProductsSimpleSlider } from "src/feature/ProductSimpleSlider";
import { SpecialOffer } from "src/feature/SpecialOffer";
import { ArticleContainer } from "src/feature/ArticlesBlock/ui";
import okkoMobile from 'src/shared/assets/images/mock/okko-mobile.png';
import okko from 'src/shared/assets/images/mock/okko.png';
import { Link } from "react-router-dom";

export const HomePage: FC = () => {
  return (
    <>
      <style>
        {
        `
          .okko {
            background-image: url(${okko});
            background-size: cover;
          }
          
          @media (min-width: 768) {
            .okko {
              background-image: url(${okkoMobile});
            }
          }
        `}
      </style>

      <Container classNames="pb-20" offsetX={false} fullContainer={true}>
        <PromoSlider />
        <AdvertisementBlock />
        <ProductSlider />
        <div className="px-5 md:px-0 mb-[40px] md:mb-[120px]">
          <div className="okko max-w-[1720px] flex-col items-center p-5 pt-[77px] min-h-[200px] md:pt-[398px] m-auto w-full flex justify-center rounded-[15px] md:pb-[84px]">
            <div className='w-[285px] mb-[15px] md:mb-[40px] md:w-[640px] text-center text-[18px] md:text-[32px] leading-[18px] md:leading-[32px] font-[700]'>Кибер-спортивные мероприятия в Окко</div>
            <Link to={'/'} className="w-[156px] md:w-[195px] bg-primary-200 rounded-full px-[43px] md:px-[60px] py-[12px] text-[15px] font-[500]">Смотреть</Link>
          </div>
        </div>
        <ProductsSimpleSlider />
        <SpecialOffer />
        <ArticleContainer />
      </Container>
    </>
  );
};
