import { createAsyncThunk } from "@reduxjs/toolkit";
import { EmailsList } from "src/feature/DeliveryDetails/model/index";
import { addUserEmail, getUserEmails, postRemoveEmail, setEmailPrimary } from "src/feature/DeliveryDetails/api";
import {EmailsInterface, SetEmailInterface} from "src/feature/DeliveryDetails/model/types";


// Получение списка email
export const fetchUserEmails = createAsyncThunk(
  'delivery/fetchUserEmails',
  async (_, { dispatch}) => {
  try {
    const {data} = await getUserEmails();
    if (data) {
      dispatch(EmailsList(data));
    }
  } catch (e) {
    console.error("Error fetching emails:", e);
  }}
);

// Добавить новую почту
export const addEmail = createAsyncThunk(
  'delivery/addUserEmails',
  async (data: EmailsInterface, {dispatch}) => {
    try {
      const response = await addUserEmail(data);
      return response.data;
    } catch (error) {
      console.log('postUserEmails: ', error);
      throw error;
    } finally {
      dispatch(fetchUserEmails());
    }
  }
);

// Удалить ранее добавленную почту
export const removeUserEmail = createAsyncThunk(
  'delivery/RemoveUserEmail',
  async (data: SetEmailInterface, {dispatch}) => {
    try {
      const response = await postRemoveEmail(data);
      return response.data;
    } catch (error) {
      console.log('RemoveUserEmail: ', error);
      throw error;
    } finally {
      dispatch(fetchUserEmails());
    }
  }
);

// Установить почту как основной
export const setPrimaryEmail = createAsyncThunk(
  'delivery/SetPrimaryEmail',
  async (data: SetEmailInterface, {dispatch}) => {
    try {
      await setEmailPrimary(data)
    } catch (error) {
      console.log('SetPrimaryEmail: ', error);
      throw error;
    } finally {
      dispatch(fetchUserEmails());
    }
  }
);
