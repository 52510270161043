import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SliderState } from './types';

const initialState: SliderState = {
  cards: [],
  currentIndex: 0,
  isLoading: false,
  error: null,
};

export const SliderSpecialOfferSlice = createSlice({
  name: 'sliderSpecialOffer',
  initialState,
  reducers: {
    setCards(state, action: PayloadAction<any>) {
      state.cards = action.payload;
    },
    nextSlide(state) {
      state.currentIndex = (state.currentIndex + 1) % state.cards.length;
    },
    prevSlide(state) {
      state.currentIndex = (state.currentIndex - 1 + state.cards.length) % state.cards.length;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
  },
});

export const { setCards, nextSlide, prevSlide, setError } = SliderSpecialOfferSlice.actions;