import { configureStore } from '@reduxjs/toolkit';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import { SupportSlice } from 'src/feature/Support';
import { DeliverySlice } from "src/feature/DeliveryDetails/model";
import { PromoSliderSlice } from "src/feature/PromoSlider/model";
import { AdvertisingHomePageSlice } from "src/feature/AdvertisementBlock/model";
import { ProductFilterSliderSlice } from "src/feature/FilterSlider/model";
import { SliderSpecialOfferSlice } from "src/feature/SpecialOffer/model";
import { ArticleSliderSlice } from "src/feature/ArticlesBlock/model";

export const store = configureStore({
  reducer: {
    support: SupportSlice.reducer,
    delivery: DeliverySlice.reducer,
    promoSliders: PromoSliderSlice.reducer,
    advertisingList: AdvertisingHomePageSlice.reducer,
    productFilterSlider: ProductFilterSliderSlice.reducer,
    specialOffer: SliderSpecialOfferSlice.reducer,
    articleContainer: ArticleSliderSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;