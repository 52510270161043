// export const isDevApi = process.env.REACT_APP_BUILD === 'development';
export const isDevApi = true;

export const AUTH_URL = isDevApi
  ? 'https://sber-bot-auth-dev.bots.winsolutions.ru/'
  : 'https://sber-bot-auth-dev.bots.winsolutions.ru/';

export const CATALOG_URL = 'https://breeze-bot-catalog-dev.bots.winsolutions.ru';

export const PAYMENT_URL = 'https://sber-bot-payment-back-dev.bots.winsolutions.ru';

export const SUPPORT_URL = 'https://tech-support-dev.bots.winsolutions.ru/api';
export const SBERID_CLIENT_ID = '580fc9c4-8693-4852-9f52-2aee4ccbfebb';