import { useContext, useMemo, useState } from 'react';
import BasketService from 'src/shared/api/basket/BasketService';
import { ProductType } from 'src/shared/api/catalog';
import { BasketContext, UserContext } from 'src/shared/store';

interface UseAddToBasketProps {
  product?: ProductType | null;
  products?: ProductType[];
}

export const useAddToBasket = ({ product, products }: UseAddToBasketProps) => {
  const { user } = useContext(UserContext);
  const { basket, setBasket } = useContext(BasketContext);
  const [isLoading, setIsLoading] = useState(false);

  const amount = useMemo(() => {
    if (!basket.length || !product) {
      return 0;
    }
    const productInBasket = basket.find(el => el.id === product.id);
    return productInBasket?.amount || 0;
  }, [basket, product]);

  const addToBasket = async () => {
    if (isLoading || !user) {
      return;
    }
    setIsLoading(true);
    try {
      if (products && products.length > 0) {
      
        const alreadyAdded = products.every(p => basket.some(b => b.id === p.id));
        if (alreadyAdded) {
          return;
        }
        const productsToAdd = products.map(p => {
          const existing = basket.find(b => b.id === p.id);
          return existing
            ? { ...p, amount: existing.amount + 1, checked: true }
            : { ...p, amount: 1, checked: true };
        });
        await BasketService.addProductToBasket(user.id, productsToAdd);

        setBasket(prev => {
          const updated = [...prev];
          products.forEach(p => {
            const index = updated.findIndex(b => b.id === p.id);
            if (index > -1) {
              updated[index] = { ...updated[index], amount: updated[index].amount + 1 };
            } else {
              updated.push({ ...p, amount: 1, checked: true });
            }
          });
          return updated;
        });
      } else if (product) {
        const existing = basket.find(b => b.id === product.id);
        const productToAdd = {
          ...product,
          amount: existing ? existing.amount + 1 : 1,
          checked: true,
        };
        await BasketService.addProductToBasket(user.id, [productToAdd]);

        setBasket(prev => {
          if (existing) {
            return prev.map(el =>
              el.id === product.id ? { ...el, amount: el.amount + 1 } : el
            );
          }
          return [...prev, productToAdd];
        });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const removeOneFromBasket = async () => {
    if (isLoading || !user || !product) {
      return;
    }
    setIsLoading(true);
    try {
      await BasketService.addProductToBasket(user.id, [
        {
          ...product,
          amount: amount - 1,
          checked: true,
          ...(amount === 1 && { removed: true }),
        },
      ]);

      setBasket(prev => {
        if (amount === 1) {
          return prev.filter(el => el.id !== product.id);
        }
        return prev.map(el => {
          if (el.id === product.id) {
            return { ...el, amount: el.amount - 1 };
          }
          return el;
        });
      });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return { addToBasket, removeOneFromBasket, isLoading, amount };
};
