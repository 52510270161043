import React from 'react';
import { MobileArticleContainer } from "src/feature/ArticlesBlock/ui/MobileArticleContainer";
import { ArticleSlider } from "src/feature/ArticlesBlock/ui/ArticleSlider";
import { useMediaQuery } from "src/shared/lib/hooks/useMediaQuery";


export const ArticleContainer: React.FC = () => {
  const isDesktop = useMediaQuery('(min-width: 768px)');

  return (
    <div className="mx-auto max-w-[1720px] px-4">
      {isDesktop ? <ArticleSlider /> : <MobileArticleContainer />}
    </div>
  );
};