import { useAppDispatch } from "src/shared/store/store";
import { nextSlide, prevSlide } from "src/feature/SpecialOffer/model";

export const useSliderControls = () => {
  const dispatch = useAppDispatch();

  const handleNext = () => {
    dispatch(nextSlide());
  };

  const handlePrev = () => {
    dispatch(prevSlide());
  };

  return { handleNext, handlePrev };
};