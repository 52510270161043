import { ApiSupport } from 'src/shared/api/ApiSupport';
import { CreateMessagePayload } from '../model/types';

export const getTicketTopics = () => ApiSupport.get(`/tickets/topics`);

export const getUserTickets = () => ApiSupport.get(`/tickets/getAll`);

export const createUserTicket = data => ApiSupport.post(`/tickets/create`, data);

export const sendUserMessage = (data: CreateMessagePayload) =>
  ApiSupport.post(`/tickets/${data.ticket_id}/messages/sendMessage`, data);

export const getChatMessages = (id: string) =>
  ApiSupport.get(`/tickets/${id}/messages/getTicketMessage`);

export const getCurrentChatTicketInfo = (id: string) => ApiSupport.get(`/tickets/${id}`);

export const connectToUserChat = (
  { ticket_id, user_id }: { ticket_id: string; user_id: number },
  onUpdate: (data: any) => void,
): WebSocket => {
  const wsUrl = `wss://tech-support-dev.bots.winsolutions.ru/ws/tickets/${ticket_id}?user_id=${user_id}`;

  const socket = new WebSocket(wsUrl);

  socket.onopen = () => {
    console.log('Connected to user chats WebSocket');
  };

  socket.onclose = () => {
    console.log('Disconnected from user chats WebSocket');
  };

  socket.onerror = error => {
    console.error('⚠️ WebSocket connection error:', error);
  };

  socket.onmessage = event => {
    try {
      const data = JSON.parse(event.data);
      onUpdate(data);
    } catch (error) {
      console.error('❗ Error parsing WebSocket message:', error);
    }
  };

  return socket;
};
