import React, {FC, useEffect} from 'react';
import { useAppDispatch, useAppSelector } from "src/shared/store/store";
import { Button } from "src/shared/ui";
import { toggleShowAll } from "src/feature/ArticlesBlock/model";
import { ArticleCard } from "src/feature/ArticlesBlock/ui/ArticleCard";
import { fetchSliderArticleList } from "src/feature/ArticlesBlock/model/action";

export const MobileArticleContainer: FC = () => {
  const dispatch = useAppDispatch();
  const { articles, showAll } = useAppSelector(state => state.articleContainer);

  const visibleArticles = showAll ? articles : articles.slice(0, 3);

  useEffect(() => {
    dispatch(fetchSliderArticleList());
  }, [dispatch]);

  return (
    <div>
      <h2 className="text-[24px] leading-[32px] font-[600] mb-5">Статьи</h2>

      <div className="grid grid-cols-1 gap-4">
        {visibleArticles.map(article => (
          <ArticleCard
            key={article.id}
            article={article}
            className="flex flex-col"
          />
        ))}
      </div>

      {!showAll && articles.length > 3 && (
        <div className="flex justify-center mt-4">
          <Button
            className="group hover:text-primary-100 transition-colors text-[#FF335F] border border-[#ff335f] h-[40px] w-full "
            variant="transparent"
            size="sm"
            onClick={() => dispatch(toggleShowAll())}
          >
            Показать все
          </Button>
        </div>
      )}
    </div>
  );
};