import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdvertisementItem } from "src/feature/AdvertisementBlock/model/types";

interface AdvertisingListInterface {
  advertisingList: AdvertisementItem[]
}

const initialState: AdvertisingListInterface = {
  advertisingList: []
}

export const AdvertisingHomePageSlice = createSlice({
  name: 'AdvertisingHomePage',
  initialState,
  reducers: {
    AdvertisingList(state, action: PayloadAction<any>) {
      state.advertisingList = action.payload;
    }
  }
});

export const {AdvertisingList} =  AdvertisingHomePageSlice.actions;