import { createAsyncThunk } from '@reduxjs/toolkit';
import { setTopics, setAppeals, setOrders, setChatTicketInfo, setChatMessages } from './index';
import {
  createUserTicket,
  getChatMessages,
  getCurrentChatTicketInfo,
  getTicketTopics,
  getUserTickets,
  sendUserMessage,
} from '../api';
import PaymentService from 'src/shared/api/payment/PaymentService';
import { CreateMessagePayload, CreateTicketPayload } from './types';

export const fetchTicketTopics = createAsyncThunk(
  'support/fetchTicketTopics',
  async (_, { dispatch }) => {
    try {
      const response = await getTicketTopics();
      dispatch(setTopics(response.data.data));
    } catch (error) {
      console.error('fetchTicketTopics error:', error);
      throw error;
    }
  },
);

export const fetchUserTickets = createAsyncThunk(
  'support/fetchUserTickets',
  async (_, { dispatch }) => {
    try {
      const response = await getUserTickets();
      dispatch(setAppeals(response.data.data));
    } catch (error) {
      console.error('fetchUserTickets error:', error);
      throw error;
    }
  },
);

export const fetchUserOrders = createAsyncThunk<void, number>(
  'support/fetchUserTickets',
  async (id, { dispatch }) => {
    try {
      const response = await PaymentService.getOrders(id);
      dispatch(setOrders(response.data));
    } catch (error) {
      console.error('fetchUserTickets error:', error);
      throw error;
    }
  },
);

export const postUserTicket = createAsyncThunk(
  'support/postUserTicket',
  async (data: CreateTicketPayload, { dispatch }) => {
    try {
      const response = await createUserTicket(data);
      return response.data;
    } catch (error) {
      console.error('postUserTicket error:', error);
      throw error;
    } finally {
      dispatch(fetchUserTickets());
    }
  },
);

export const postUserMessage = createAsyncThunk(
  'support/postUserTicket',
  async (data: CreateMessagePayload, { dispatch }) => {
    try {
      await sendUserMessage(data);
    } catch (error) {
      console.error('postUserTicket error:', error);
      throw error;
    }
  },
);

export const fetchChatTicketInfo = createAsyncThunk(
  'support/postUserTicket',
  async (id: string, { dispatch }) => {
    try {
      const response = await getCurrentChatTicketInfo(id);
      dispatch(setChatTicketInfo(response.data.data));
    } catch (error) {
      console.error('postUserTicket error:', error);
      throw error;
    } finally {
      dispatch(fetchUserTickets());
    }
  },
);

export const fetchChatMessages = createAsyncThunk(
  'support/postUserTicket',
  async (id: string, { dispatch }) => {
    try {
      const response = await getChatMessages(id);
      dispatch(setChatMessages(response.data.data));
    } catch (error) {
      console.error('postUserTicket error:', error);
      throw error;
    } finally {
      dispatch(fetchUserTickets());
    }
  },
);
