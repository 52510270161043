import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setItemsPerPage } from "src/feature/FilterSlider/model";

const GAP = 16;
const getCardWidth = () => {
  if (typeof window === 'undefined') return 233;
  if (window.innerWidth < 768) {
    return 156; // размер карточки для мобилки
  }
  return 233; // Размер карточки для десктопа
};

export const useSlider = () => {
  const dispatch = useDispatch();
  const [containerWidth, setContainerWidth] = useState(0);
  const [cardWidth, setCardWidth] = useState(getCardWidth());

  const calculateLayout = useCallback(() => {
    const container = document.querySelector('.slider-container');
    if (!container) return;

    const width = container.clientWidth;
    setContainerWidth(width);
    const currentCardWidth = getCardWidth();
    setCardWidth(currentCardWidth);

    const visibleCount = Math.floor((width + GAP) / (currentCardWidth + GAP));
    dispatch(setItemsPerPage(visibleCount));
  }, [dispatch]);

  useEffect(() => {
    calculateLayout();
    window.addEventListener('resize', calculateLayout);
    return () => window.removeEventListener('resize', calculateLayout);
  }, [calculateLayout]);

  return {
    cardWidth,
    gap: GAP,
    containerWidth,
  };
};