import React, {FC} from 'react';
import {Article} from "src/feature/ArticlesBlock/model/types";
import { ReactComponent as Comment } from 'src/shared/assets/icons/kit/Comment.svg';

interface SliderCardProps {
  article: Article;
  className?: string;
}

export const ArticleCard: FC<SliderCardProps> = ({ article, className = '' }) => {
  return (
    <div className={`overflow-hidden ${className}`}>
      <img
        src={article.imageUrl}
        alt={article.title}
        className="w-full h-[328px] md:h-[408px] object-cover rounded-[20px]"
      />
      <div className="p-4">
        <h3 className="font-[600] text-[18px] md:text-[22px] leading-[18px] md:leading-[22px] mb-[10px] md:mb-4 line-clamp-2">{article.title}</h3>
        <p className="font-[400] text-[12px] md:text-[16px] leading-[12px] md:leading-[16px] mb-4 md:mb-5 line-clamp-3">{article.description}</p>
        <div className="flex justify-between items-center text-xs text-gray-500">
          <span className="flex items-center">
            <Comment className="mr-[5px]"/>
            {article.commentsCount} comments</span>
          <span>{article.publishDate}</span>
        </div>
      </div>
    </div>
  );
};