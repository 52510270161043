import { FC, useContext, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { BasketContext } from 'src/app/providers/BasketProvider';
import { UserContext } from 'src/app/providers/UserProvider';
import { ProfileNavigation } from 'src/feature/ProfileNavigation';
import { ReactComponent as CloseIcon } from 'src/shared/assets/icons/kit/close.svg';
import { routesEnum } from 'src/shared/const';
import { Loader } from 'src/shared/ui/Loader';
import { HeaderBanner } from 'src/shared/assets/icons/kit/HeaderBanner';
import { useIsMobile } from 'src/shared/lib/hooks/useIsMobile';
import { UserIcon } from 'src/shared/assets/icons/kit/User';
import { LeftIcon } from 'src/shared/assets/icons/kit/LeftIcon';

export const Header: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isMobile } = useIsMobile();

  const { user, isUserLoading } = useContext(UserContext);
  const { basket } = useContext(BasketContext);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const fullAmount = useMemo(() => {
    if (!basket.length) {
      return 0;
    }

    return basket.reduce((sum, curr) => {
      return sum + curr.amount;
    }, 0);
  }, [basket]);

  return (
    <header>
      <div>
        {isMobile && !!user?.id && pathname !== '/' && (
          <div
            className="fixed left-4 top-4 z-1000 flex h-[40px] w-[40px] cursor-pointer flex-col items-center justify-center rounded-lg bg-darkGray"
            onClick={() => navigate(-1)}
          >
            <LeftIcon />
          </div>
        )}

        <Link
          to={'/'}
          className="fixed left-1/2 top-4 z-1000 -translate-x-1/2 cursor-pointer transition-colors hover:text-primary-100"
        >
          <HeaderBanner />
        </Link>

        {user && (
          <div
            className={`font-default-regular fixed right-4 top-4 z-1000 cursor-pointer rounded-lg bg-darkGray ${isMobile ? 'p-[8px]' : 'px-[13px] py-[8px]'}`}
            onClick={() => {
              if (!isUserLoading && !user) {
                navigate(routesEnum.LOGIN);
              }
              if (user) {
                navigate(routesEnum.PROFILE_ORDERS);
              }
            }}
          >
            {isMobile ? (
              <UserIcon />
            ) : (
              <>
                {isUserLoading && <Loader classNames="w-4 h-4 mx-8" />}
                {!isUserLoading && user && <>{user.name}</>}
                {!isUserLoading && !user && 'Войти'}
              </>
            )}
          </div>
        )}

        {isMenuOpen &&
          createPortal(
            <div className="fixed inset-0 z-2000 bg-bgPrimary p-4 text-textPrimary">
              <div className="mb-8 flex items-center justify-between">
                <div className="font-default-medium text-xl">Меню</div>

                <button className="h-5 w-5 text-textPrimary" onClick={() => setIsMenuOpen(false)}>
                  <CloseIcon className="p-0.5" />
                </button>
              </div>

              <ProfileNavigation onClose={() => setIsMenuOpen(false)} />
            </div>,
            document.body,
          )}
      </div>
    </header>
  );
};
