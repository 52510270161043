import { FC } from 'react';
import cn from 'classnames';

interface SwitchProps {
  isActive?: boolean;
  onClick?: () => void;
  classNames?: string;
}

export const Switch: FC<SwitchProps> = ({ isActive, onClick, classNames }) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        'bg-[rgba(57,57,57,1)] transition-all-slow relative flex h-[25px] w-[45px] cursor-pointer rounded-full p-0.5',
        classNames,
      )}
    >
      <div
        className={cn(
          { 'left-[3px]': !isActive, 'left-[23px]': isActive,
          'bg-[rgba(97,97,97,1)]': !isActive,
          'bg-[rgba(255,51,95,1)]': isActive,
          },
          'transition-all-slow top-[3px] absolute flex aspect-square h-[19px] w-[19px] rounded-full bg-[rgba(97,97,97,1)]',
        )}
      />
    </div>
  );
};
