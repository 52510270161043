import { createAsyncThunk } from '@reduxjs/toolkit';
import { setProducts, setLoading, setError } from 'src/feature/FilterSlider/model/index';

const mockProducts = [
  {
    id: 1,
    title: 'Товар 1',
    price: 1000,
    discountPrice: 800,
    image: '/product1.jpg',
    isRecommended: true,
    isOnSale: true,
  },
  {
    id: 2,
    title: 'Товар 1',
    price: 1000,
    discountPrice: 800,
    image: '/product1.jpg',
    isRecommended: true,
    isOnSale: true,
  },
  {
    id: 3,
    title: 'Товар 1',
    price: 1000,
    discountPrice: 800,
    image: '/product1.jpg',
    isRecommended: true,
    isOnSale: true,
  },
  {
    id: 4,
    title: 'Товар 1',
    price: 1000,
    discountPrice: 800,
    image: '/product1.jpg',
    isRecommended: true,
    isOnSale: true,
  },
  {
    id: 5,
    title: 'Товар 1',
    price: 1000,
    discountPrice: 800,
    image: '/product1.jpg',
    isRecommended: true,
    isOnSale: true,
  },
  {
    id: 6,
    title: 'Товар 1',
    price: 1000,
    discountPrice: 800,
    image: '/product1.jpg',
    isRecommended: true,
    isOnSale: true,
  },
  {
    id: 7,
    title: 'Товар 1',
    price: 1000,
    discountPrice: 800,
    image: '/product1.jpg',
    isRecommended: true,
    isOnSale: true,
  },
  {
    id: 8,
    title: 'Товар 1',
    price: 1000,
    discountPrice: 800,
    image: '/product1.jpg',
    isRecommended: true,
    isOnSale: true,
  },
  {
    id: 9,
    title: 'Товар 1',
    price: 1000,
    discountPrice: 800,
    image: '/product1.jpg',
    isRecommended: true,
    isOnSale: true,
  },
  {
    id: 10,
    title: 'Товар 1',
    price: 1000,
    discountPrice: 800,
    image: '/product1.jpg',
    isRecommended: true,
    isOnSale: true,
  },
];

export const fetchProducts = createAsyncThunk(
  'homePage/fetchProducts',
  async (_, { dispatch }) => {
    try {
      dispatch(setLoading(true));

      dispatch(setProducts(mockProducts));
      dispatch(setLoading(false));
    } catch (err) {
      dispatch(setError('Ошибка загрузки товаров'));
      dispatch(setLoading(false));
    }
  }
);