import { ChangeEvent, FC, useContext, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { ReactComponent as ArrowIcon } from 'src/shared/assets/icons/kit/arrow.svg';
import { ReactComponent as CatalogIcon } from 'src/shared/assets/icons/kit/catalog.svg';
import { ReactComponent as CloseIcon } from 'src/shared/assets/icons/kit/close.svg';
import { ReactComponent as HeartIcon } from 'src/shared/assets/icons/kit/heart.svg';
import { ReactComponent as HomeIcon } from 'src/shared/assets/icons/kit/home.svg';
import { ReactComponent as LoupeIcon } from 'src/shared/assets/icons/kit/loupe.svg';
import { ReactComponent as ShoppingCartIcon } from 'src/shared/assets/icons/kit/shopping-cart.svg';
import { routesEnum } from 'src/shared/const';
import { debounce } from 'src/shared/lib/utils';
import { BasketContext, CatalogContext } from 'src/shared/store';

import { useSearchHistory } from '../hooks';
import { useIsMobile } from 'src/shared/lib/hooks/useIsMobile';
import { ProjectSearch } from 'src/entity/ProjectSearch';

export const Footer: FC = () => {
  const searchRef = useRef<HTMLInputElement>(null);
  const { isMobile } = useIsMobile();

  const { pathname } = useLocation();
  const { filters, setFilters } = useContext(CatalogContext);
  const { basket } = useContext(BasketContext);

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [search, setSearch] = useState<string>('');

  const { add, list, remove, removeAll } = useSearchHistory(search);

  const fullAmount = useMemo(() => {
    if (!basket.length) {
      return 0;
    }

    return basket.reduce((sum, curr) => {
      return sum + curr.amount;
    }, 0);
  }, [basket]);

  const navigate = useNavigate();

  const handleBack = () => navigate(-1);

  const linkStyles = cn(
    { 'px-5': !isSearchOpen },
    'group flex items-center justify-center h-full rounded-full relative h-[48px]',
  );

  const getActiveStyles = (path: string) => pathname === path && 'bg-midGray2 hover:bg-textLighter';
  const getIconStyles = (path: string) =>
    cn(pathname === path ? 'fill-bgPrimary' : 'fill-textPrimary', 'w-5 h-5');

  const links = [
    {
      path: routesEnum.HOME,
      icon: <HomeIcon className={getIconStyles(routesEnum.HOME)} />,
    },
    {
      path: routesEnum.CATALOG,
      icon: <CatalogIcon className={getIconStyles(routesEnum.CATALOG)} />,
    },
    {
      path: routesEnum.FAVORITES,
      icon: <HeartIcon className={getIconStyles(routesEnum.FAVORITES)} />,
    },
  ];

  const left = {
    '/': 3,
    '/catalog': 63,
    '/favorites': 123,
  };

  const handleWipeInput = () => {
    setSearch('');
  }

  const handleSearch = () => {
    const search = searchRef?.current?.value || '';
    setFilters(prev => ({ ...prev, offset: 0, search, isAllLoading: false }));
    search && add(search);
    setIsSearchOpen(false);

    handleWipeInput();
    navigate(routesEnum.CATALOG_SEARCH)
  };

  const handleChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, 300);

  return (
    <footer>
      {isSearchOpen && (
        <div className="fixed inset-0 z-2000 flex items-start justify-center bg-[#181818] bg-opacity-95 pt-[40px]">
          <div className="relative overflow-hidden rounded-2xl">
            <ProjectSearch
              list={list}
              handleChange={handleChange}
              searchRef={searchRef}
              handleSearch={handleSearch}
              handleDeleteSearch={remove}
              removeAll={removeAll}
              value={search}
              onSearchClose={() => setIsSearchOpen(false)}
            />
          </div>
        </div>
      )}

      <div className="fixed bottom-4 left-1/2 z-1000 flex h-[54px] -translate-x-1/2 gap-4">
        {!isMobile && (
          <div
            className={cn(
              {
                'w-0 p-0 opacity-0':
                  pathname !== '/basket' &&
                  !pathname.includes('/product') &&
                  !pathname.includes('/profile') &&
                  !pathname.includes('/policy'),
                'opacity-1 w-20 p-[3px]':
                  pathname === '/basket' ||
                  pathname.includes('/product') ||
                  pathname.includes('/profile') ||
                  pathname.includes('/policy'),
              },
              'transition-all-slow h-full cursor-pointer rounded-full bg-bgPressed backdrop-blur-3xl',
            )}
            onClick={handleBack}
          >
            <div className={linkStyles}>
              <ArrowIcon className={getIconStyles('')} />
            </div>
          </div>
        )}

        <div
          className={cn(
            { 'bg-bgPressed backdrop-blur-3xl': !isSearchOpen },
            'relative flex h-full rounded-full p-[3px]',
          )}
        >
          <div
            className={cn(
              {
                'w-[60px]': Boolean(left?.[pathname]),
                'w-0 p-0 opacity-0': !Boolean(left?.[pathname]) || isSearchOpen,
              },
              'transition-all-slow absolute top-[3px] h-[48px] rounded-full bg-bgPrimary',
            )}
            style={{ left: `${left?.[pathname] || 0}px` }}
          />

          {links.map((link, i) => (
            <Link
              to={link.path}
              onClick={() => setFilters(prev => ({ ...prev, search: '' }))}
              key={`footer-link-${i}`}
              className={cn(
                { 'w-0 p-0 opacity-0': isSearchOpen },
                linkStyles,
                getActiveStyles(link.path),
              )}
            >
              {link.icon}
            </Link>
          ))}

          <div
            className={cn(
              {
                'w-0 p-0 opacity-0': isSearchOpen,
                'bg-bgPrimary hover:bg-bgHover': !!filters.search,
              },
              linkStyles,
              getActiveStyles(''),
              'cursor-pointer border border-[#3E3E3E] bg-[#282828]',
            )}
            onClick={() => setIsSearchOpen(true)}
          >
            <LoupeIcon
              className="
                
                'h-5 fill-white' w-5
              "
            />
            {/* 
            {filters.search && (
              <div className="font-default-medium max-w-32 truncate pl-2 text-black-100">
                {filters.search}
              </div>
            )} */}
          </div>

          {/* <div
            className={cn(
              'z-1000 flex h-full cursor-pointer items-center rounded-full bg-bgPressed',
              { 'w-0 p-0 opacity-0': !isSearchOpen, 'px-6': isSearchOpen },
            )}
            onClick={() => setIsSearchOpen(false)}
          >
            <CloseIcon className="h-5 w-5 text-textLighter" />
          </div> */}
        </div>

        <div
          className={cn(
            {
              'w-0 p-0 opacity-0': pathname === '/basket' || isSearchOpen,
              'opacity-1 w-20 p-[3px]': pathname !== '/basket' && !isSearchOpen,
            },
            'transition-all-slow relative h-full rounded-full bg-bgPressed backdrop-blur-3xl',
          )}
        >
          {fullAmount ? (
            <div
              className={cn(
                'font-default-medium bord absolute -top-1 right-0 rounded-full px-2 text-sm',
                'border-2 border-bgPrimary bg-primary-100 text-white',
                'border border-white',
              )}
            >
              {fullAmount}
            </div>
          ) : null}

          {!isSearchOpen && pathname !== '/basket' && (
            <Link
              to="/basket"
              className={cn(linkStyles, {
                'invisible opacity-0': pathname === '/basket' || isSearchOpen,
              })}
            >
              <ShoppingCartIcon className={getIconStyles('')} />
            </Link>
          )}
        </div>
      </div>
    </footer>
  );
};
