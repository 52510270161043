import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdvertisingList } from "src/feature/AdvertisementBlock/model/index";

const data = [
  {
    description: 'с подпиской СберПрайм+',
    type: 'advantage',
    value: 35,
    url: '',
    sberTag: true,
  },
  {
    description: 'Быстрый перекус без паузы в игре',
    type: 'discount',
    value: 20,
    url: '',
    sberTag: false,
  },
  {
    description: 'на лучшие онлайн-стратегии',
    type: 'cashback',
    value: 35,
    url: '',
    sberTag: false,
  },
  {
    description: 'Уже скоро самый выгодный день в году!',
    type: 'cashback',
    value: 65,
    url: '',
    sberTag: true,
  },
  {
    description: 'Успей купить с максимальной выгодой',
    type: 'discount',
    value: 50,
    url: '',
    sberTag: false,
  },
];
export const fetchAdvertisingList = createAsyncThunk(
  'homePage/Advertising',
  async (_, { dispatch}) => {
    try {
      dispatch(AdvertisingList(data));
    } catch (e) {
      console.error("Error fetching emails:", e);
    }}
);