import { FC, useRef, useState } from 'react';
import { Button } from 'src/shared/ui';
import { useIsMobile } from 'src/shared/lib/hooks/useIsMobile';
import { useClickOutside } from 'src/shared/lib/hooks';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as TelegramIcon } from 'src/shared/assets/icons/kit/telegram.svg';
import { ReactComponent as VkIcon } from 'src/shared/assets/icons/kit/vk.svg';
import { useNavigate } from 'react-router-dom';
import { routesEnum } from 'src/shared/const';

export const FloatingPanel = () => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();
  useClickOutside(ref, () => setIsOpen(false));

  const navigateToPolicyPage = () => {
    setIsOpen(false);
    navigate(routesEnum.POLICY);
  };

  return (
    <div className="fixed bottom-7 right-5 z-1000 hidden d-xs:flex">
      <Button
        onClick={() => setIsOpen(!isOpen)}
        className="rounded-[14px] bg-white px-4 py-2 text-black shadow-lg"
        style={{ boxShadow: '0px 2px 8px 0px #A0A0A040' }}
      >
        Контакты и полезные ссылки
      </Button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="fixed inset-0 z-1001 flex items-end justify-end bg-black bg-opacity-85 p-4"
          >
            <motion.div
              ref={ref}
              initial={{ x: 300, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: 300, opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="max-h-[378px] w-full max-w-[532px] rounded-2xl bg-bgBlack p-10 shadow-lg"
            >
              <div className="grid grid-cols-[auto_min-content] gap-6 text-white">
                <div>
                  <p className="mb-6 text-sm font-semibold text-white">ГеймерсХаб</p>
                  <p className="mb-4 text-xs text-textGray2">Сотрудничество</p>
                  <p className="text-xs">Юридический адрес:</p>
                  <p className="mb-4 text-xs text-textGray2">
                    127287, г. Москва, ул. Хуторская 2-я, д. 38А, стр. 26
                  </p>
                  <p className="text-xs">Юридическое лицо:</p>
                  <p className="mb-6 text-xs text-textGray2">ООО "Название"</p>
                  <div className="mt-2 flex gap-2">
                    <div className="flex h-8 w-8 cursor-pointer items-center justify-center rounded bg-darkGray">
                      <TelegramIcon />
                    </div>
                    <div className="flex h-8 w-8 cursor-pointer items-center justify-center rounded bg-darkGray">
                      <VkIcon />
                    </div>
                  </div>
                </div>
                <div className="min-w-[130px]">
                  <p className="mb-6 text-sm font-semibold">Покупателям</p>
                  <p className="mb-4 text-xs text-textGray2">Помощь покупателю</p>
                  <p className="text-xs text-textGray2">Акции</p>
                </div>
              </div>
              <div
                onClick={navigateToPolicyPage}
                className="mt-8 cursor-pointer border-t border-white pt-4 text-center text-[9px] text-white"
              >
                Условия использования сайта⠀⠀Обработка персональных данных⠀⠀Условия заказа и
                доставки
                <p className="mt-2 text-textGray2">2016-2025 "Маркетплейс"</p>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
