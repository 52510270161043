export const ruI18n = {
  search: 'Поиск',
  clearSearch: 'Очистить',
  notFound: 'Ничего не найдено',
  categories: {
    search: 'Результаты поиска',
    recent: 'Недавние',
    smileys: 'Смайлики и эмоции',
    people: 'Люди и тело',
    nature: 'Животные и природа',
    foods: 'Еда и напитки',
    activity: 'Активность',
    places: 'Путешествия и места',
    objects: 'Объекты',
    symbols: 'Символы',
    flags: 'Флаги',
    custom: 'Свои',
  },
  // skinTones: {
  //   choose: 'Выбрать тон кожи',
  //   1: 'По умолчанию',
  //   2: 'Светлый',
  //   3: 'Средний',
  //   4: 'Смуглый',
  //   5: 'Тёмный',
  // },
};

export const emojiPickerClassname = 'emoji-picker';