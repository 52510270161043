import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'src/entity/Container';
import { routesEnum } from 'src/shared/const';
import { Button } from 'src/shared/ui';

export const EndPaymentPage: FC = () => {
  const navigate = useNavigate();

  return (
    <Container title="Спасибо за заказ!">
      <div className="mt-20 flex items-center justify-center">
        <div className="max-w-[32rem]">
          <div className="font-default-medium mb-10 text-xl text-black-100">
            Доставим на указанный e-mail в течении 30 минут
          </div>

          <div className="grid grid-cols-2 gap-2">
            <Button
              size="lg"
              className="w-full"
              variant="primary"
              onClick={() => navigate(routesEnum.PROFILE_ORDERS)}
            >
              К заказам
            </Button>
            <Button size="lg" className="w-full" onClick={() => navigate(routesEnum.CATALOG)}>
              В каталог
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
};
