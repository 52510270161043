import { SliderCard } from '../model/types';
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setCards } from "src/feature/SpecialOffer/model/index";

export const mockSliderCards: SliderCard[] = [
  {
    id: 1,
    image: 'https://fileshare.drawbot.winsolutions.ru/download/digitalcatalog/development/catalog/images/Крутой кот 5_Image_638774838061724607.png',
    title: 'Bellatores',
    link: '#',
  },
  {
    id: 2,
    image: 'https://fileshare.drawbot.winsolutions.ru/download/digitalcatalog/development/catalog/images/Крутой кот 5_Image_638774838061724607.png',
    title: 'Lost Ark',
    link: '#',
  },
  {
    id: 3,
    image: 'https://fileshare.drawbot.winsolutions.ru/download/digitalcatalog/development/catalog/images/Крутой кот 5_Image_638774838061724607.png',
    title: 'AtomicHeart',
    link: '#',
  },
  {
    id: 4,
    image: 'https://fileshare.drawbot.winsolutions.ru/download/digitalcatalog/development/catalog/images/Крутой кот 5_Image_638774838061724607.png',
    title: 'Аллоды Онлайн',
    link: '#',
  },
  {
    id: 5,
    image: 'https://fileshare.drawbot.winsolutions.ru/download/digitalcatalog/development/catalog/images/Крутой кот 5_Image_638774838061724607.png',
    title: 'Карточка 5',
    link: '#',
  },
  {
    id: 6,
    image: 'https://fileshare.drawbot.winsolutions.ru/download/digitalcatalog/development/catalog/images/Крутой кот 5_Image_638774838061724607.png',
    title: 'Карточка 6',
    link: '#',
  },
];

export const fetchSliderSpecialOfferList = createAsyncThunk(
  'homePage/SliderSpecialOfferList',
  async (_, { dispatch}) => {
    try {
      dispatch(setCards(mockSliderCards));
    } catch (e) {
      console.error("Error fetching emails:", e);
    }}
);