import { FC, useState, useEffect, useCallback } from 'react';
import { ReactComponent as CookieIcon } from 'src/shared/assets/icons/kit/Cookie.svg';
import { useIsMobile } from 'src/shared/lib/hooks/useIsMobile';
import { Button } from 'src/shared/ui';

interface BannerProps {
  onClose?: () => void;
}

export const CookieBanner: FC<BannerProps> = ({ onClose }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { isMobile } = useIsMobile();

  useEffect(() => {
    const hasSeenBanner = localStorage.getItem('cookieBannerSeen');
    setIsVisible(!hasSeenBanner ? true : false);
  }, []);

  const handleClose = useCallback(() => {
    setIsVisible(false);
    localStorage.setItem('cookieBannerSeen', 'true');
    onClose && onClose();
  }, [onClose]);

  if (!isVisible) return null;

  return (
    <div className="z-1001 fixed inset-0 flex w-screen flex-col justify-end bg-black bg-opacity-[0.85]">
      <div>
        {!isMobile ? (
          <div className="bg-bgBlack relative flex items-center justify-around gap-2 p-6">
            <div className="flex items-center gap-8">
              <CookieIcon />
              <div>
                <p className="text-lg font-semibold text-textPrimary">Сайт использует Cookie.</p>
                <p className="mt-2 max-w-3xl text-sm text-textPrimary">
                  Используя данный сайт, вы даете согласие на использование файлов cookie, данных об
                  IP-адресе и местоположении, помогающих нам сделать его удобнее для вас. Подробнее
                </p>
              </div>
            </div>
            <Button
              onClick={handleClose}
              className="h-[60px] min-w-[205px] rounded-[30px] bg-primary-100 px-6 text-center text-white"
            >
              Принять и закрыть
            </Button>
          </div>
        ) : (
          <div className="bg-bgBlack flex w-full flex-col items-center rounded-t-2xl  p-4">
            <div className="align-center flex items-center gap-[16px]">
              <CookieIcon />
              <p className="text-center text-sm font-semibold text-textPrimary">
                Сайт использует Cookie.
              </p>
            </div>
            <p className="mt-2 px-4 text-center text-sm text-textPrimary">
              Используя данный сайт, вы даете согласие на использование файлов cookie, данных об
              IP-адресе и местоположении, помогающих нам сделать его удобнее для вас. Подробнее
            </p>
            <Button
              onClick={handleClose}
              className="mt-4 w-full rounded-[30px] bg-primary-100 py-2 text-center text-sm text-white"
            >
              Принять и закрыть
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
