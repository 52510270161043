import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product, ProductSliderState, FilterType } from './types';

const initialState: ProductSliderState = {
  products: [],
  filteredProducts: [],
  currentFilter: 'recommended',
  currentPage: 0,
  itemsPerPage: 4,
  isLoading: false,
  error: null,
};

export const ProductFilterSliderSlice = createSlice({
  name: 'productFilterSlider',
  initialState,
  reducers: {
    setProducts(state, action: PayloadAction<Product[]>) {
      state.products = action.payload;
      state.filteredProducts = filterProducts(action.payload, state.currentFilter);
    },
    setFilter(state, action: PayloadAction<FilterType>) {
      state.currentFilter = action.payload;
      state.filteredProducts = filterProducts(state.products, action.payload);
      state.currentPage = 0;
    },
    nextPage(state) {
      const totalPages = Math.ceil(state.filteredProducts.length / state.itemsPerPage);
      state.currentPage = (state.currentPage + 1) % totalPages;
    },
    prevPage(state) {
      const totalPages = Math.ceil(state.filteredProducts.length / state.itemsPerPage);
      state.currentPage = (state.currentPage - 1 + totalPages) % totalPages;
    },
    setItemsPerPage(state, action: PayloadAction<number>) {
      state.itemsPerPage = action.payload;
      state.currentPage = 0;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
  },
});

function filterProducts(products: Product[], filter: FilterType): Product[] {
  switch(filter) {
    case 'recommended':
      return products.filter(product => product.isRecommended);
    case 'new':
      return products.filter(product => product.isNew);
    case 'bestsellers':
      return products.filter(product => product.isBestseller);
    case 'sale':
      return products.filter(product => product.isOnSale);
    case 'preorders':
      return products.filter(product => product.isPreorder);
    default:
      return products;
  }
}

export const {
  setProducts,
  setFilter,
  nextPage,
  prevPage,
  setItemsPerPage,
  setLoading,
  setError,
} = ProductFilterSliderSlice.actions;

export default ProductFilterSliderSlice.reducer;