import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cn from "classnames";

import { useAppDispatch } from "src/shared/store/store";
import { fetchAdvertisingList } from "src/feature/AdvertisementBlock/model/action";
import { AdvertisingList } from "src/feature/AdvertisementBlock/model/getters";

import { ReactComponent as IconLink } from 'src/shared/assets/icons/kit/link.svg';

import bannerRed from "src/shared/assets/images/mock/banner-red.gif";
import bannerRedMobile from "src/shared/assets/images/mock/bannerRedMobile.gif";

import bannerBlue from "src/shared/assets/images/mock/banner-blue.gif";
import bannerblueMobile from "src/shared/assets/images/mock/banner-blue-mobile.gif";

import zero from "src/shared/assets/images/mock/0.png";
import first from "src/shared/assets/images/mock/1.png";
import two from "src/shared/assets/images/mock/2.png";
import three from "src/shared/assets/images/mock/3.png";

export const AdvertisementBlock = () => {
  const advertisingList = useSelector(AdvertisingList);
  const dispatch = useAppDispatch();

  useEffect( () => {
    dispatch(fetchAdvertisingList());
  }, []);

  const getTagStatus = (key) => {
    const tagMap = {
      'discount': 'Скидка',
      'cashback': 'Кэшбэк',
      'advantage': 'Выгода'
    };

    return tagMap[key];
  };

  const masonryStyle = (value) => {
    const masonryMap = {
      '0': 'hidden xl:flex xl:col-start-1 xl:col-end-2 xl:row-start-3 xl:row-end-4',
      '1': 'hidden xl:flex xl:col-start-2 xl:col-end-3 xl:row-start-3 xl:row-end-4',
      '2': 'col-start-1 col-end-2 row-start-2 row-end-3 xl:col-start-3 xl:col-end-4 xl:row-start-1 xl:row-end-2',
      '3': 'col-start-2 col-end-3 row-start-2 row-end-3 xl:col-start-4 xl:col-end-5 xl:row-start-1 xl:row-end-2',
      '4': 'col-start-1 col-end-3 row-start-3 row-end-4 xl:col-start-3 xl:col-end-5 xl:row-start-2 xl:row-end-4 xl:col-span-2 xl:row-span-2',
    };

    return masonryMap[value];
  }

  return (
    <>
      <style>
        {
          `
          .gif {
            background-size: cover;
          }
          
          .gifRed {
            background-image: url(${bannerRed});
            
          }
          .gif0 {
            background-image: url(${zero});
          }
          .gif1 {
            background-image: url(${first});
          }
          .gif2 {
            background-image: url(${two});
          }
          .gif3 {
            background-image: url(${three});
          }
          .gif4 {
            background-image: url(${bannerBlue});
            
          }
          
          @media (min-width: 768) {
            .gifRed {
              background-image: url(${bannerRedMobile});  
            }
            
            .gif0 {
            
            }
            .gif1 {}
            .gif2 {}
            .gif3 {}
            .gif4 {
              background-image: url(${bannerblueMobile});
            }
          }
          `
        }
      </style>


      <div className='
        max-w-[1720px]
        mx-auto
        mb-[40px]
        xl:my-[120px]
        grid
        gap-[4px_4px]
        auto-cols-[1fr]
        grid-cols-[1fr_1fr]
        grid-rows-[448px_240px_448px]
        xl:grid-cols-[1fr_1fr_1fr_1fr]
        xl:grid-rows-[406px_147px_406px]'>
        <div className='gifRed gif col-start-1 col-end-3 row-start-1 row-end-2 xl:col-start-1 xl:col-end-3 xl:row-start-1 xl:row-end-3 col-span-2 xl:row-span-2 px-4 pt-[55px] pb-[76px] flex justify-center items-center'>
          <div className="flex flex-col justify-center text-center py-8 px-[35px] backdrop-blur-lg max-w-[480px]">
            <div className="text-[32px] leading-[32px] font-[600] text-[#fe335f] mb-6">Приз лучшему игроку</div>
            <div className="text-[14px] leading-[18px] font-[500] text-white mb-3">Побеждай на турнирах и играй бесплатно Приглашай друзей и получай двойные бонусы</div>
            <div className="text-[10px] leading-[18px] font-[400] text-white/60 mb-3">Присоединяйся к онлайн турниру, зарабатывай очки и получай реальное игровое время бесплатно!</div>
            <Link to={'/'} className="flex w-full items-center self-center justify-center max-w-[306px] text-[16px] leading-[20px] font-[500] text-primary-200 px-[30px] py-[12px] rounded-full border-2 border-primary-200">
              <IconLink className="mr-[10px]"/>Пригласить друзей</Link>
          </div>
        </div>

        {advertisingList.map((item, id) =>
          <div className={cn(masonryStyle(id), `gif${id} gif`)}>
            <div className="p-3 md:p-5 h-full w-full flex flex-col justify-between">
              <div className="flex w-fit items-center bg-white p-1 pl-3 text-primary-200 text-[16px] md:text-[18px] leading-[16px] md:leading-[18px] rounded-full">{getTagStatus(item.type)} <div className={cn(
                "text-[16px] md:text-[22px] ml-1 text-white leading-[30px] px-4 rounded-full ", {
                  'bg-gradient-to-r from-[#8654CC] to-[#A3EAA2]': item.sberTag,
                  'bg-primary-200': !item.sberTag
                }
              )}>{`${item.value} %`}</div>
              </div>
              <div className="font-[600] text-[16px] md:text-[32px] leading-[16px] md:leading-[32px] max-w-[385px]">{item.description}</div>
            </div>
          </div>
        )}
      </div>

    </>
  );
};


