import React, { TextareaHTMLAttributes } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string;
  register?: UseFormRegisterReturn;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  rowsCount?: number;
  borderRadius?: number;
  height?: number;
}

export const TextArea: React.FC<TextAreaProps> = ({
  label,
  error,
  register,
  leftIcon,
  rightIcon,
  rowsCount,
  borderRadius,
  height,
  ...props
}) => {
  return (
    <>
      <style>{`
        .textarea-gradient-wrapper {
          position: relative;
          border-radius: ${borderRadius ? `${borderRadius}px` : '12px'};
          background: linear-gradient(to bottom, #2F2F2F, #D9DADA99);
          padding: 1px;
        }

        .textarea-gradient-inner {
          background: #262626;
          border-radius: ${borderRadius ? `${borderRadius}px` : '12px'};
          box-shadow: inset 0 3px 6px 0 #00000070;
          position: relative;
          ${height ? `height: ${height}px;` : ''}
        }

        .textarea-gradient-inner textarea {
          width: 100%;
          min-height: ${rowsCount ? '' : '120px'};
          ${height ? `height: ${height}px;` : ''}
          background: transparent;
          border: none;
          outline: none;
          resize: none;
          font-size: 16px;
          line-height: 30px;
          color: #ffffff;
          font-family: inherit;
          padding: 12px 16px;
          padding-left: calc(16px + var(--leftIconOffset, 0px));
          padding-right: calc(16px + var(--rightIconOffset, 0px));
        }

        .textarea-gradient-inner textarea::placeholder {
          color: #838484;
        }

        .textarea-error {
          border: 1px solid red !important;
        }

        .icon-left {
          position: absolute;
          top: 16px;
          z-index: 1;
          left: 12px;
        }
        
        .icon-right {
          position: absolute;
          z-index: 1;
          top: 6px;
          right: 4px;
        }
      `}</style>

      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {label && (
          <label
            style={{
              marginBottom: '8px',
              color: 'rgba(255,255,255,0.9)',
              fontSize: '14px',
              fontWeight: 500,
            }}
          >
            {label}
          </label>
        )}

        <div className={`textarea-gradient-wrapper ${error ? 'textarea-error' : ''}`}>
          <div
            className="textarea-gradient-inner"
            style={{
              ['--leftIconOffset' as any]: leftIcon ? '24px' : '0px',
              ['--rightIconOffset' as any]: rightIcon ? '24px' : '0px',
            }}
          >
            {leftIcon && <div className="icon-left">{leftIcon}</div>}
            {rightIcon && <div className="icon-right">{rightIcon}</div>}

            <textarea {...(register || {})} rows={rowsCount} {...props} />
          </div>
        </div>

        {error && <span style={{ marginTop: '4px', fontSize: '12px', color: 'red' }}>{error}</span>}
      </div>
    </>
  );
};
