import React from 'react';
import { FilterType } from 'src/feature/FilterSlider/model/types';

interface FilterTabsProps {
  currentFilter: FilterType;
  onFilterChange: (filter: string) => void;
}

const filters: { id: FilterType; label: string }[] = [
  { id: 'recommended', label: 'Рекомендуемые' },
  { id: 'new', label: 'Новинки' },
  { id: 'bestsellers', label: 'Бестселлеры' },
  { id: 'sale', label: 'Акции' },
  { id: 'preorders', label: 'Предзаказы' },
];

export const FilterTabs: React.FC<FilterTabsProps> = ({ currentFilter, onFilterChange }) => {
  return (
    <div className="flex overflow-x-auto pb-2 -mx-4 px-4 scrollbar-hide">
      {filters.map(filter => (
        <button
          key={filter.id}
          onClick={() => onFilterChange(filter.id)}
          className={`whitespace-nowrap px-4 py-2 mr-2 rounded-full text-[13px] leading-5 transition-colors ${
            currentFilter === filter.id
              ? 'bg-primary-200 text-white'
              : 'bg-darkGray hover:bg-gray-300'
          }`}
        >
          {filter.label}
        </button>
      ))}
    </div>
  );
};