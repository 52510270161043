import { FC } from 'react';
import cn from 'classnames';

interface DiscountPercentProps {
  percent: number;
  big?: boolean;
  className?: string;
}

export const DiscountPercent: FC<DiscountPercentProps> = ({ percent, big, className }) => {
  return (
    <div className="w-fit -skew-x-12 rounded bg-[#404040] px-1">
      <div
        className={cn(
          {
            'text-xs': !Boolean(big),
            'text-base': Boolean(big),
          },
          'skew-x-12 text-white',
          className
        )}
      >{`-${percent}%`}</div>
    </div>
  );
};
