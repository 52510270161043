import { Article } from '../model/types';
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setArticles } from "src/feature/ArticlesBlock/model/index";

export const mockArticles: Article[] = [
  {
    id: '1',
    imageUrl: 'https://fileshare.drawbot.winsolutions.ru/download/digitalcatalog/development/catalog/images/Игровая валюта Free Fire: 100 Diamonds_Image_638699419514287825.png',
    title: 'Музыкальное приключение Koira появится 1 апреля',
    description: 'Сегодня, 18 августа, завершится международный чемпионат Intel Extreme Masters Cologne 2024 по Counter-Strike 2 с призовым фондом $ 1 млн',
    commentsCount: 25,
    publishDate: '2023-05-15',
  },
  {
    id: '2',
    imageUrl: 'https://fileshare.drawbot.winsolutions.ru/download/digitalcatalog/development/catalog/images/Fortnite - Набор «Прикосновение пустоты» + 1000 Vbucks_Image_638698738865797227.png',
    title: 'The Legend of Heroes: Trails Through Daybreak II: Обзор сиквела одной из лучших JRPG',
    description: 'Сегодня, 18 августа, завершится международный чемпионат Intel Extreme Masters Cologne 2024 по Counter-Strike 2 с призовым фондом $ 1 млн',
    commentsCount: 25,
    publishDate: '2023-04-28',
  },
  {
    id: '3',
    imageUrl: 'https://fileshare.drawbot.winsolutions.ru/download/digitalcatalog/development/catalog/images/Игровая валюта Free Fire: 100 Diamonds_Image_638699419514287825.png',
    title: 'Шухей Йошида: портировать игры с PS5 на ПК — это «почти как печатать деньги»',
    description: 'Сегодня, 18 августа, завершится международный чемпионат Intel Extreme Masters Cologne 2024 по Counter-Strike 2 с призовым фондом $ 1 млн',
    commentsCount: 25,
    publishDate: '2023-05-10',
  },
  {
    id: '4',
    imageUrl: 'https://fileshare.drawbot.winsolutions.ru/download/digitalcatalog/development/catalog/images/Fortnite - Набор «Прикосновение пустоты» + 1000 Vbucks_Image_638698738865797227.png',
    title: 'Kingdom Come: Deliverance II: Лепота',
    description: 'Сегодня, 18 августа, завершится международный чемпионат Intel Extreme Masters Cologne 2024 по Counter-Strike 2 с призовым фондом $ 1 млн',
    commentsCount: 25,
    publishDate: '2023-05-05',
  },
  {
    id: '5',
    imageUrl: 'https://fileshare.drawbot.winsolutions.ru/download/digitalcatalog/development/catalog/images/Fortnite - Набор «Прикосновение пустоты» + 1000 Vbucks_Image_638698738865797227.png',
    title: 'The Legend of Heroes: Trails Through Daybreak II: Обзор сиквела одной из лучших JRPG',
    description: 'Сегодня, 18 августа, завершится международный чемпионат Intel Extreme Masters Cologne 2024 по Counter-Strike 2 с призовым фондом $ 1 млн',
    commentsCount: 25,
    publishDate: '2023-04-20',
  },
  {
    id: '6',
    imageUrl: 'https://fileshare.drawbot.winsolutions.ru/download/digitalcatalog/development/catalog/images/Fortnite - Набор «Прикосновение пустоты» + 1000 Vbucks_Image_638698738865797227.png',
    title: 'The Legend of Heroes: Trails Through Daybreak II: Обзор сиквела одной из лучших JRPG',
    description: 'Сегодня, 18 августа, завершится международный чемпионат Intel Extreme Masters Cologne 2024 по Counter-Strike 2 с призовым фондом $ 1 млн',
    commentsCount: 25,
    publishDate: '2023-05-12',
  },
];

export const fetchSliderArticleList = createAsyncThunk(
  'homePage/SliderArticleList',
  async (_, { dispatch}) => {
    try {
      dispatch(setArticles(mockArticles));
    } catch (e) {
      console.error("Error fetching emails:", e);
    }}
);