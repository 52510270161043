import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Order, Ticket, Topics, UserMessage } from './types';

interface SupportState {
  tickets: Ticket[];
  orders: Order[];
  topics: Topics[];

  chatTicketInfo: any;
  chatMessages: UserMessage[];
}

const initialState: SupportState = {
  tickets: [],
  orders: [],
  topics: [],

  chatTicketInfo: {},
  chatMessages: [],
};

export const SupportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    setAppeals(state, action: PayloadAction<Ticket[]>) {
      state.tickets = action.payload;
    },
    setOrders(state, action: PayloadAction<Order[]>) {
      state.orders = action.payload;
    },
    setTopics(state, action: PayloadAction<Topics[]>) {
      state.topics = action.payload;
    },
    setChatMessages(state, action: PayloadAction<any>) {
      state.chatMessages = action.payload;
    },
    setChatTicketInfo(state, action: PayloadAction<any>) {
      state.chatTicketInfo = action.payload;
    },
    setChatMessagesFromSockets(state, action: PayloadAction<UserMessage>) {
      if (Array.isArray(state.chatMessages)) {
        state.chatMessages.push(action.payload);
      } else {
        state.chatMessages = [action.payload];
      }
    },
  },
});

export const {
  setAppeals,
  setOrders,
  setTopics,
  setChatMessages,
  setChatMessagesFromSockets,
  setChatTicketInfo,
} = SupportSlice.actions;
