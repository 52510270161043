import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PromoSlide} from "src/feature/PromoSlider/model/types";

interface PromoSliderInitialInterface {
  slides: PromoSlide[]
}

const initialState: PromoSliderInitialInterface = {
  slides: []
}

export const PromoSliderSlice = createSlice({
  name: 'PromoSliderSlice',
  initialState,
  reducers: {
    SlidesList(state, action: PayloadAction<any>) {
      state.slides = action.payload;
    }
  }
});

export const { SlidesList } = PromoSliderSlice.actions;
