import { useContext, useEffect, useState, useRef, useCallback, MutableRefObject } from 'react';
import { ProductType } from 'src/shared/api/catalog';
import CatalogService from 'src/shared/api/catalog/CatalogService';
import { offsetStep } from 'src/shared/const';
import { debounce } from 'src/shared/lib/utils';
import { CatalogContext, GroupContext, UserContext } from 'src/shared/store';
import { SortType } from 'src/shared/types';
import { useLocation } from 'react-router-dom';

export const useCatalog = ({
  selectedSort,
  isBought,
  isFavorite,
  prevPathname,
}: {
  selectedSort: SortType;
  isBought?: boolean;
  isFavorite?: boolean;
  prevPathname?: string | null;
}) => {
  const {
    filters,
    setFilters,
    setInitialFilters,
    setIsLoadingInitialFilters,
    isLoadingInitialFilters,
  } = useContext(CatalogContext);
  const { setGroups, setIsGroupsLoading } = useContext(GroupContext);
  const { user } = useContext(UserContext);
  const location = useLocation();

  const [products, setProducts] = useState<ProductType[]>([]);
  const [isGlobalLoading, setIsGlobalLoading] = useState(true);
  const isFetchingRef = useRef(false);
  const isFiltersUpdatingRef = useRef(false);

  const checkPosition = useCallback(
    debounce(() => {
      if (isFetchingRef.current || isFiltersUpdatingRef.current) return;

      const { clientHeight, scrollHeight } = document.documentElement;
      const position = window.pageYOffset + clientHeight;
      const threshold = scrollHeight - position;

      if (threshold <= 200) {
        isFetchingRef.current = true;

        setFilters(prev => {
          if (prev.offset === prev.offset + offsetStep) return prev;
          return { ...prev, offset: prev.offset + offsetStep };
        });
      }
    }, 400),
    [],
  );

  useEffect(() => {
    document.addEventListener('scroll', checkPosition);
    return () => document.removeEventListener('scroll', checkPosition);
  }, [checkPosition]);

  const fetchFilters = async () => {
    if (!user) return;

    setIsGlobalLoading(true);
    setIsLoadingInitialFilters(true);
    setIsGroupsLoading(true);
    isFiltersUpdatingRef.current = true;

    try {
      const groups = await CatalogService.getGroups();
      const page = isBought ? 'bought' : isFavorite ? 'favorite' : 'catalog';
      const filtersRes = await CatalogService.getFilters(user.id, groups.data?.[0]?.id, page);

      setGroups(groups.data);
      setInitialFilters({
        ...filtersRes.data,
        priceMax: +(filtersRes.data.priceMax.replace(',', '.') || 0),
        priceMin: +(filtersRes.data.priceMin.replace(',', '.') || 0),
      });

      setFilters(prev => ({
        ...prev,
        offset: 0,
        isAllLoading: false,
        selectedGroup: groups.data?.[0] || null,
        price: {
          min: +(filtersRes.data.priceMin.replace(',', '.') || 0),
          max: +(filtersRes.data.priceMax.replace(',', '.') || 0),
        },
      }));
    } catch (e) {
      console.error('Failed to fetch filters', e);
    } finally {
      setIsLoadingInitialFilters(false);
      setIsGroupsLoading(false);
      isFiltersUpdatingRef.current = false;
    }
  };

  useEffect(() => {
    if (location.pathname !== prevPathname) {
      setProducts([]);
    }

    fetchFilters().then(() => {
      if (!isLoadingInitialFilters) {
        setFilters(prev => ({ ...prev, offset: 0 }));
      }
    });
  }, [isFavorite, isBought, user, location.pathname]);

  useEffect(() => {
    if (isLoadingInitialFilters || !filters.selectedGroup || !user) return;

    isFetchingRef.current = true;

    const fetchProducts = async () => {
      try {
        const { data } = isFavorite
          ? await CatalogService.getFavoriteProducts(selectedSort.value, filters, user.id, isBought)
          : await CatalogService.getProducts(selectedSort.value, filters, user.id);

        if (!data?.length) {
          setFilters(prev => ({ ...prev, isAllLoading: true }));
        }

        setProducts(prev => (filters.offset > 0 ? [...prev, ...data] : data));
      } catch (e) {
        console.error('Failed to fetch products', e);
      } finally {
        isFetchingRef.current = false;
        setIsGlobalLoading(false);
      }
    };

    fetchProducts();
  }, [filters.offset, filters.price, filters.search, filters.parameters, selectedSort, isBought]);

  return { isLoading: isGlobalLoading, products, setProducts, isLoadingInitialFilters };
};
