import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EmailsInterface } from "src/feature/DeliveryDetails/model/types";

interface EmailInitialState {
  emails: EmailsInterface[]
}

const initialState: EmailInitialState = {
  emails: []
}

export const DeliverySlice = createSlice({
  name: 'delivery',
  initialState,
  reducers: {
    EmailsList(state, action: PayloadAction<EmailsInterface[]>){
      state.emails = action.payload
    },
    DeleteEmail(state, action: PayloadAction<number>) {
      state.emails = state.emails.filter(item => item.id !== action.payload)
    },
    AddEmail(state, action: PayloadAction<EmailsInterface>) {
      if (Array.isArray(state.emails)) {
        state.emails.push(action.payload);
      } else {
        state.emails = [action.payload];
      }
    },
  }
});

export const {EmailsList, AddEmail, DeleteEmail} = DeliverySlice.actions;
