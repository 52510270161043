import { FC } from 'react';
import cn from 'classnames';
import { addSpaces } from 'src/shared/lib/utils';

interface DiscountPriceProps {
  price: string;
  size?: 's' | 'm' | 'l' | 'sm';
  classNames?: string
}

export const DiscountPrice: FC<DiscountPriceProps> = ({ price, size = 's', classNames}) => {
  return (
    <div
      className={cn(
        { 'text-xs': size === 's', 'text-base': size === 'm', 'text-lg': size === 'l', 'text-sm font-medium': size === 'sm' },
        'relative w-fit whitespace-nowrap text-grey-500',
        'line-through decoration-[#EB4650]',
        classNames
      )}
    >{`${addSpaces(+price.replace(',', '.'))} ₽`}</div>
  );
};
