import React, { useState, DragEvent, ChangeEvent, useRef, MouseEvent } from 'react';
import { PhotoIcon } from 'src/shared/assets/icons/kit/Photo';

interface DropImageAreaProps {
  maxFiles?: number;
  onChange?: (files: Attachment[]) => void;
}

export interface Attachment {
  file_data: string;
  file_name: string;
  file_size: number;
  mime_type: string;
}

export const DropImageArea: React.FC<DropImageAreaProps> = ({ maxFiles = 10, onChange }) => {
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleAddFiles = (files: FileList) => {
    const fileArray = Array.from(files).slice(0, maxFiles - attachments.length);

    Promise.all(
      fileArray.map(
        file =>
          new Promise<Attachment>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              resolve({
                file_data: reader.result as string,
                file_name: file.name,
                file_size: file.size,
                mime_type: file.type,
              });
            };
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
          }),
      ),
    )
      .then(newAttachments => {
        const updatedAttachments = [...attachments, ...newAttachments];
        setAttachments(updatedAttachments);
        onChange?.(updatedAttachments);
      })
      .catch(error => {
        console.error('Ошибка при чтении файлов', error);
      });
  };

  const handleRemoveFile = (fileIndex: number) => {
    const updated = attachments.filter((_, i) => i !== fileIndex);
    setAttachments(updated);
    onChange?.(updated);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleAddFiles(e.dataTransfer.files);
      e.dataTransfer.clearData();
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleAddFiles(e.target.files);
      e.target.value = '';
    }
  };

  // Проверяем, куда кликнули
  const handleAreaClick = (e: MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;
    const isOverlay = target.closest('.drop-image-overlay');
    const isItem = target.closest('.drop-image-item');
    if (!isOverlay && !isItem) {
      inputRef.current?.click();
    }
  };

  return (
    <>
      <style>{`
        .drop-image-wrapper {
          position: relative;
          border-radius: 12px;
          background: linear-gradient(to bottom, #2f2f2f, #444444);
          padding: 1px;
        }
        .drop-image-inner {
          background: #262626;
          border-radius: 12px;
          box-shadow: inset 0 3px 6px 0 #00000070;
          min-height: 140px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          position: relative;
          cursor: pointer;
        }
        .drop-image-placeholder {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          padding: 20px;
        }
        .drop-image-placeholder .icon {
          margin-bottom: 8px;
        }
        .drop-image-placeholder p {
          margin: 0;
        }
        .drop-image-placeholder .text-muted {
          color: #838189;
        }
        .drop-image-placeholder .text-red {
          color: #ff335f;
        }
        .drop-file-input {
          display: none;
        }
        .drop-image-preview {
          display: flex;
          gap: 12px;
          flex-wrap: wrap;
          justify-content: flex-start;
          padding: 16px;
          width: 100%;
        }
        .drop-image-item {
          position: relative;
          width: 110px;
          height: 130px;
          border-radius: 8px;
          overflow: hidden;
          cursor: default;
        }
        .drop-image-item img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
        .drop-image-item:hover .drop-image-overlay {
          opacity: 1;
        }
        .drop-image-overlay {
          position: absolute;
          inset: 0;
          background: rgba(0,0,0,0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          font-size: 18px;
          font-weight: bold;
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
          cursor: pointer;
        }
        .drop-image-overlay span {
          background-color: rgba(0, 0, 0, 0.6);
          padding: 4px 8px;
          border-radius: 4px;
        }
        .drop-image-overlay span:hover {
          background-color: rgba(255, 0, 0, 0.8);
        }
      `}</style>

      <div className="drop-image-wrapper" onDragOver={handleDragOver} onDrop={handleDrop}>
        <div className="drop-image-inner" onClick={handleAreaClick}>
          {attachments.length === 0 ? (
            <div className="drop-image-placeholder">
              <div className="icon">
                <PhotoIcon />
              </div>
              <p className="text-muted">
                Перетащите или <span className="text-red">загрузите до {maxFiles} фотографий</span>
              </p>
              <p className="text-muted">JPEG или PNG до 10 МБ</p>
            </div>
          ) : (
            <div className="drop-image-preview">
              {attachments.map((file, index) => {
                return (
                  <div className="drop-image-item" key={`${file.file_name}-${index}`}>
                    <img src={file.file_data} alt={file.file_name} />
                    <div className="drop-image-overlay" onClick={() => handleRemoveFile(index)}>
                      <span>X</span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <input
            ref={inputRef}
            className="drop-file-input"
            type="file"
            multiple
            onChange={handleFileChange}
          />
        </div>
      </div>
    </>
  );
};
