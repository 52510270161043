import { createAsyncThunk } from "@reduxjs/toolkit";
import { SlidesList } from "src/feature/PromoSlider/model/index";

const data = [
  {
    image: 'https://avatars.mds.yandex.net/get-mpic/4591438/img_id886598544946017893.jpeg/9',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive', 'green'],
    params: ['AstrumGames', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://avatars.mds.yandex.net/get-mpic/4591438/img_id886598544946017893.jpeg/9',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['AstrumGames', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://avatars.mds.yandex.net/get-mpic/4591438/img_id886598544946017893.jpeg/9',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['AstrumGames', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://avatars.mds.yandex.net/get-mpic/4591438/img_id886598544946017893.jpeg/9',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['AstrumGames', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://avatars.mds.yandex.net/get-mpic/4591438/img_id886598544946017893.jpeg/9',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['AstrumGames', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://avatars.mds.yandex.net/get-mpic/4591438/img_id886598544946017893.jpeg/9',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['AstrumGames', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://avatars.mds.yandex.net/get-mpic/4591438/img_id886598544946017893.jpeg/9',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['AstrumGames', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://avatars.mds.yandex.net/get-mpic/4591438/img_id886598544946017893.jpeg/9',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['AstrumGames', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://avatars.mds.yandex.net/get-mpic/4591438/img_id886598544946017893.jpeg/9',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['AstrumGames', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://avatars.mds.yandex.net/get-mpic/4591438/img_id886598544946017893.jpeg/9',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['AstrumGames', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://avatars.mds.yandex.net/get-mpic/4591438/img_id886598544946017893.jpeg/9',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['AstrumGames', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://avatars.mds.yandex.net/get-mpic/4591438/img_id886598544946017893.jpeg/9',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['AstrumGames', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://avatars.mds.yandex.net/get-mpic/4591438/img_id886598544946017893.jpeg/9',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['AstrumGames', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://avatars.mds.yandex.net/get-mpic/4591438/img_id886598544946017893.jpeg/9',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['AstrumGames', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://avatars.mds.yandex.net/get-mpic/4591438/img_id886598544946017893.jpeg/9',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['AstrumGames', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://avatars.mds.yandex.net/get-mpic/4591438/img_id886598544946017893.jpeg/9',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['AstrumGames', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://avatars.mds.yandex.net/get-mpic/4591438/img_id886598544946017893.jpeg/9',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['AstrumGames', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://avatars.mds.yandex.net/get-mpic/4591438/img_id886598544946017893.jpeg/9',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['AstrumGames', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://avatars.mds.yandex.net/get-mpic/4591438/img_id886598544946017893.jpeg/9',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['AstrumGames', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
  {
    image: 'https://avatars.mds.yandex.net/get-mpic/4591438/img_id886598544946017893.jpeg/9',
    imagePreview: '',
    title: "Marvel's Spider-Man 2",
    description: 'Сразитесь с Веномом и примерьте черный костюм в долгожданном продолжении приключений Человека-Паука!',
    tags: ['discount', 'exclusive'],
    params: ['AstrumGames', 'Шутеры', 'PS5', 'Турция'],
    price: {
      newPrice: '2499',
      oldPrice: '4765',
      discount: 39,
    },
    onFavorite: true,
  },
];

export const fetchPromoSlider = createAsyncThunk(
  'homePage/fetchPromoSlider',
  async (_, { dispatch}) => {
    try {
      dispatch(SlidesList(data));
    } catch (e) {
      console.error("Error fetching emails:", e);
    }}
);