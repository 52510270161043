import { useRef, useEffect, useState } from 'react';
import { AdminCloseIcon } from 'src/shared/assets/icons/kit/AdminClose';
import { ReactComponent as LoupeIcon } from 'src/shared/assets/icons/kit/loupe.svg';
import { useIsMobile } from 'src/shared/lib/hooks/useIsMobile';
import { Button } from 'src/shared/ui';

export const ProjectSearch = ({
  list,
  searchRef,
  handleSearch,
  handleDeleteSearch,
  handleChange,
  removeAll,
  onSearchClose,
  value,
}) => {
  const isSearchOpen = list.length > 0;
  const searchContainerRef = useRef<HTMLDivElement | null>(null);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const { isMobile } = useIsMobile();

  const clearInput = () => {
    if (searchRef.current) {
      searchRef.current.value = '';
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target as Node)
      ) {
        onSearchClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onSearchClose]);

  return (
    <div className="fixed inset-0 z-2000 flex items-start justify-center bg-opacity-90 pt-[40px]">
      <div
        ref={searchContainerRef}
        className="box-border flex w-[100%] min-w-[350px] max-w-[839px] flex-col items-start justify-start gap-[-1px] px-[16px]"
      >
        <div
          className={`${isSearchOpen && isInputFocused ? 'rounded-[30px_30px_0px_0px]' : 'rounded-[30px]'} relative box-border flex h-14 shrink-0 grow-0 basis-auto flex-row items-center justify-start gap-5 self-stretch border border-solid border-[rgba(217,218,218,0.60)] bg-neutral-800 py-[30px] pl-5 shadow-[inset_0px_5px_10px_rgba(0,0,0,0.70)]`}
        >
          {!isMobile && (
            <LoupeIcon className="box-border flex h-[21px] w-[21px] shrink-0 grow-0 basis-auto text-white" />
          )}
          <input
            ref={searchRef}
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => setIsInputFocused(false)}
            onChange={handleChange}
            className="m-0 box-border w-[567px] shrink-0 grow-0 basis-auto p-0 text-left font-normal leading-4 text-[white]"
          />
          <div className="absolute right-2 flex items-center gap-4">
            {!!value.length && (
              <div onClick={clearInput}>
                <AdminCloseIcon className="cursor-pointer" color="#7B7B7B" />
              </div>
            )}
            <Button
              variant="primary"
              onClick={handleSearch}
              className={`font-medium leading-5 text-[white] cursor-pointer h-11 block box-border grow-0 shrink-0 basis-auto rounded-[30px] border-[none] ${isMobile ? 'w-[50px]' : 'min-w-[185px]'}`}
            >
              {isMobile ? <LoupeIcon /> : 'Искать'}
            </Button>
          </div>
        </div>
        {isSearchOpen && isInputFocused ? (
          <div className="box-border flex shrink-0 grow-0 basis-auto flex-row items-center justify-start gap-5 self-stretch  rounded-[0px_0px_30px_30px] border border-solid border-[rgba(217,218,218,0.60)] bg-neutral-800 py-2 shadow-[inset_0px_-5px_10px_rgba(0,0,0,0.50)]">
            <div className="max-h-[350px] w-[100%] overflow-hidden">
              <div className="box-border flex shrink-0 grow-0 basis-auto cursor-pointer flex-col items-start justify-start gap-2.5 px-5 py-[15px]">
                <div className="box-border flex shrink-0 grow-0 basis-auto flex-row items-center justify-start justify-between gap-2.5 self-stretch">
                  <p className="m-0 box-border shrink-0 grow-0 basis-auto p-0 text-left text-base font-normal leading-5 text-[white]">
                    Вы искали
                  </p>
                  <div className="cursor-pointer text-[14px] text-scarlet-200" onClick={removeAll}>
                    Очистить
                  </div>
                </div>
              </div>

              {list.slice(0, 5).map(el => (
                <div
                  key={el}
                  onClick={() => {
                    if (!searchRef?.current) {
                      return;
                    }
                    searchRef.current.value = el;
                    handleSearch();
                  }}
                  className="box-border flex shrink-0 grow-0 basis-auto cursor-pointer flex-col items-start justify-start gap-2.5 px-5 py-[15px] last:rounded-b-[30px] hover:bg-[#1B1B1B]"
                >
                  <div className="box-border flex shrink-0 grow-0 basis-auto flex-row items-center justify-start justify-between gap-[212px] self-stretch">
                    <p className="m-0 box-border shrink-0 grow-0 basis-auto p-0 text-left text-base font-normal leading-5 text-[white]">
                      {el}
                    </p>
                    <div onClick={() => handleDeleteSearch(el)}>
                      <AdminCloseIcon className="m-1 h-[10px] w-[10px]" color="#7B7B7B" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
