import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Article, SliderState } from './types';

const initialState: SliderState = {
  articles: [],
  currentIndex: 0,
  showAll: false,
};

export const ArticleSliderSlice = createSlice({
  name: 'articleSlider',
  initialState,
  reducers: {
    setArticles: (state, action: PayloadAction<Article[]>) => {
      state.articles = action.payload;
    },
    nextSlide: (state) => {
      if (state.currentIndex < state.articles.length - 4) {
        state.currentIndex += 1;
      }
    },
    prevSlide: (state) => {
      if (state.currentIndex > 0) {
        state.currentIndex -= 1;
      }
    },
    toggleShowAll: (state) => {
      state.showAll = !state.showAll;
    },
  },
});

export const { setArticles, nextSlide, prevSlide, toggleShowAll } = ArticleSliderSlice.actions;
export default ArticleSliderSlice.reducer;