import { CSSProperties, FC, ReactNode } from 'react';
import cn from 'classnames';
import { Title } from 'src/shared/ui';

interface ContainerProps {
  classNames?: string;
  title?: string;
  head?: ReactNode;
  children?: ReactNode;
  style?: CSSProperties;
  offsetX?: boolean;
  fullContainer?: boolean
}

export const Container: FC<ContainerProps> = ({ classNames, title, head, children, style, fullContainer = false, offsetX = true }) => {
  return (
    <div className={
      cn(`mx-auto w-full max-w-[${fullContainer ? '100%' : '1720px'}] p-${offsetX ? '5' : 0}`,
        classNames)} style={style}>
      {title && <Title>{title}</Title>}
      {head}
      {children}
    </div>
  );
};
