import { useEffect } from 'react';

export const useTrackHistory = () => {
  const updateHistory = () => {
    const historyData = JSON.parse(localStorage.getItem('history') || '{}');

    const newHistory = {
      previous: historyData.current || '',
      current: window.location.pathname,
    };

    localStorage.setItem('history', JSON.stringify(newHistory));
  };

  useEffect(() => {
    updateHistory();

    window.addEventListener('popstate', updateHistory);

    const originalPushState = window.history.pushState;
    window.history.pushState = function (...args) {
      originalPushState.apply(window.history, args);
      updateHistory();
    };

    return () => {
      window.removeEventListener('popstate', updateHistory);
      window.history.pushState = originalPushState;
    };
  }, []);
};
