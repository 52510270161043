import React, {
  cloneElement,
  FC,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Slider from 'react-slick';
import type SliderType from 'react-slick';
import cn from 'classnames';
import { ResponsiveObject, Settings } from 'react-slick';
import styles from './styles.module.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ArrowLeft } from 'src/shared/assets/icons/kit/ArrowLeft';
import { ArrowRight } from 'src/shared/assets/icons/kit/ArrowRight';

export interface ICarouselProps
  extends Partial<
    Pick<
      Settings,
      | 'slidesToShow'
      | 'slidesToScroll'
      | 'afterChange'
      | 'beforeChange'
      | 'className'
      | 'initialSlide'
      | 'infinite'
      | 'focusOnSelect'
    >
  > {
  items: React.ReactElement[];
  isArrowsOnTop?: boolean;
  isCustomPositionArrow?: boolean;
  showArrows?: boolean;
  variableWidth?: boolean;
  centerMode?: boolean;
  rows?: number;
  slickDotsGap?: number;
  responsive?: ResponsiveObject[];
  dots?: boolean;
  className: string;
  gameArrows?: boolean;
  slidePadding?: number;
  onSlideChange?: (index: number) => void;
}

export interface IArrowProps {
  position: 'left' | 'right';
  className?: string;
  disabled?: boolean;
  size?: number;
  onClick?: () => void;
}

export interface InnerSliderWithProps {
  props: {
    slidesToShow: number;
  };
}

export const Arrow: FC<IArrowProps> = ({ position, className, disabled, size, onClick }) => {
  const isDisabled = disabled || className?.includes('slick-disabled');

  return (
    <button
      type="button"
      disabled={isDisabled}
      className={cn(styles.arrowWrapper, { [styles.disabled]: isDisabled }, [styles[position]])}
      onClick={onClick}
      style={{ ...(size && { width: size, height: size }) }}
    >
      {position === 'left' ? <ArrowLeft /> : <ArrowRight />}
    </button>
  );
};

const CarouselFC: FC<ICarouselProps> = props => {
  const {
    focusOnSelect = false,
    infinite = false,
    items,
    isArrowsOnTop,
    afterChange,
    showArrows = true,
    slidesToShow,
    responsive,
    variableWidth = false,
    centerMode = false,
    rows,
    dots = true,
    className,
    onSlideChange,
  } = props;

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const sliderRef = useRef<SliderType | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const actualSlidesToShow =
    (sliderRef.current?.innerSlider as InnerSliderWithProps)?.props?.slidesToShow ||
    slidesToShow ||
    1;

  const isDisabledLeftArrow = useMemo(() => currentSlideIndex === 0, [currentSlideIndex]);

  const isDisabledRightArrow = useMemo(() => {
    const itemsLength = items.length;
    return currentSlideIndex + actualSlidesToShow >= itemsLength;
  }, [currentSlideIndex, items.length, actualSlidesToShow]);

  const handleAfterChange = useCallback(
    (index: number) => {
      setCurrentSlideIndex(index);
      onSlideChange?.(index);
      afterChange?.(index);
    },
    [afterChange, onSlideChange],
  );

  const handlePrevClick = () => {
    sliderRef?.current?.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef?.current?.slickNext();
  };

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(0);
        window.dispatchEvent(new Event('resize'));
      }
    }, 300);
    
    return () => clearTimeout(timer);
  }, [items.length]);

  return (
    <div ref={containerRef} className={styles.carouselWrapper} id="customSlide">
      <div className={cn(styles.carouselArrowsOnTop)}>
        <Arrow onClick={handlePrevClick} position="left" disabled={isDisabledLeftArrow} size={32} />
        <Arrow
          onClick={handleNextClick}
          position="right"
          disabled={isDisabledRightArrow}
          size={32}
        />
      </div>

      <Slider
        {...props}
        variableWidth={variableWidth}
        infinite={infinite}
        initialSlide={0}
        centerMode={centerMode}
        focusOnSelect={focusOnSelect}
        nextArrow={<></>}
        prevArrow={<></>}
        className={cn(
          styles.carouselContainer,
          !isArrowsOnTop && styles.carouselContainerSideArrows,
          className,
        )}
        ref={sliderRef}
        arrows={!isArrowsOnTop && showArrows}
        afterChange={handleAfterChange}
        dots={dots}
        responsive={responsive}
        rows={rows || 1}
        slidesPerRow={1}
        slidesToShow={slidesToShow}
      >
        {items.map((item, index) => (
          <div key={index} className="customSlide">
            {cloneElement(item)}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export const Carousel = memo(CarouselFC);
